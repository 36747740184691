import React from 'react';
import Layout from '../components/Layout';

const Service_one = () => {
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div
                        className="services_banner"
                        style={{
                            backgroundImage: `url(https://images.pexels.com/photos/5076511/pexels-photo-5076511.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)`,
                        }}
                    >
                        <h1>Mobile Application Development</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title" style={{fontSize: 'x-large'}}>Get Next Generation Wearable App Developed With Tyrion
                            Tech !</h1>

                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                We at Audaco Technologies believes in moving forward with the latest trends and technology. In
                                order to keep up with the upcoming market trends and increasing consumer demands, we
                                jumped into the service of providing wearable app development on Android and iOS
                                platforms.

                                Right from the strategy, design, development till deployment, Audaco Technologies is your
                                trusted partner for the best wearable app development. Our wearable app development
                                solutions range from creating a smart and intuitive application for wearable devices
                                till solutions that integrate with a wide range of sensors; empowering mobility on a
                                large scale.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Service_one;
